import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Flex,
  Switch,
  Text,
} from '@chakra-ui/react';
import lodash from 'lodash';
import React, {
  ComponentProps,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import usePagedList from 'shared/src/hooks/usePagedList';
import useRequest from 'shared/src/hooks/useRequest';
import Spinner from 'web-react-ui/src/chakra/Spinner';
import {
  Empty,
  TableCell,
  TableList,
  TableRow,
} from 'web-react-ui/src/chakra/TableList/TableList';
import businessesModule from '../../../../modules/businesses';
import client from '../../../../services/client';
import getContentTypeProps from '../components/getContentTypeProps';
import SectionHeader from '../components/SectionHeader';
import Campaign from '../interfaces/Campaign.interface';

const buildCampaignColumns = (contentTypes: Array<string> = []) => ([
  {
    key: 'content',
    label: '',
    cell: {
      w: '100%',
    },
    skeletonText: {
      noOfLines: 2,
      w: '100%',
    },
  },
  ...contentTypes.map(c => (
    {
      key: c,
      label: getContentTypeProps(c).label,
      cell: {
        minW: '6rem',
      },
      skeletonCircle: {},
    }
  )),
]);

const fetchCampaigns = async ({ businessId }: { businessId: string }): Promise<{ items: Array<Campaign> }> => {
  const campaigns = await client.businesses.for(businessId).social.campaigns.list();
  const grouped = lodash.groupBy(campaigns.items, c => c.type.split(':')[0]);
  const items = Object.values(grouped);
  const contentTypes = Array.from(new Set(campaigns.items.map(c => c.contentType)));

  // HACK: sneak contentTypes through in response.page
  const page = campaigns.page;
  page.contentTypes = contentTypes;

  return {
    items,
    page,
  };
};

const Campaigns = () => {
  const business = useSelector(state => businessesModule.selectors.business.getData(state));
  const [refetchId, setRefetchId] = useState(0);
  const campaignsRequest = usePagedList(fetchCampaigns, { businessId: business.id, refetch: refetchId });

  const contentTypes = campaignsRequest.page.contentTypes;

  const columns = buildCampaignColumns(contentTypes);

  // Watch value of `items` so we can re-load in the background without hiding the whole list
  const campaignsLoading = !campaignsRequest.items.length;

  const noEnabledCampaigns = !campaignsRequest.loading
    && !campaignsRequest.items.flat().some((c: Campaign) => c.enabled);

  return (
    <Flex direction="column" gap="1em">
      <SectionHeader>Post Types</SectionHeader>
      {
        noEnabledCampaigns && (
          <Alert status="warning">
            <AlertIcon />
            <AlertTitle>No Enabled Post Types</AlertTitle>
            <AlertDescription>
              These are the types of posts that will go out to the connected accounts.
              If none are enabled, no posts will go out.
            </AlertDescription>
          </Alert>
        )
      }
      <TableList isLoading={campaignsLoading} columns={columns}>
        <Empty isEmpty={campaignsRequest.empty}>
          <Text fontSize="xl">No Campaigns To Display</Text>
        </Empty>
        {campaignsRequest.items.map((campaignGroup: Array<Campaign>) => (
          <CampaignItem campaignGroup={campaignGroup}
                        columns={columns}
                        key={campaignGroup[0].id}
                        contentTypes={contentTypes}
                        onChange={() => setRefetchId(v => v + 1)}
          />
        ))}
      </TableList>
    </Flex>
  );
};

const CampaignToggle = ({ campaign, onChange }: {
  campaign?: Campaign, onChange: () => void
}) => {
  const business = useSelector(state => businessesModule.selectors.business.getData(state));
  const [enabled, setEnabled] = useState(campaign?.enabled);

  const toggleCampaign = useRequest(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      // Store synthetic event value...
      const checked = event.target.checked;
      setEnabled(checked);

      const campaignRepo = client
        .businesses.for(business.id)
        .social.campaigns.for(campaign.type);

      await (checked ? campaignRepo.enable() : campaignRepo.disable())
        .catch(() => setEnabled(!checked));

      onChange?.();
    },
  );

  if (!campaign) return null;

  /* Note: TypeScript is just lying. Spinner's type is completely valid. */
  return (
    <Spinner loading={toggleCampaign.loading}>
      <Switch size="lg" isChecked={enabled} onChange={toggleCampaign.run} />
    </Spinner>
  );
};

const CampaignItem = ({ campaignGroup, columns, contentTypes, onChange }: {
  campaignGroup: Array<Campaign>,
  columns: Array<any>,
  contentTypes: Array<string>,
  onChange: ComponentProps<typeof CampaignToggle>['onChange']
}) => {
  const campaign = campaignGroup[0];
  const post = campaignGroup.find(c => c.contentType === 'post');
  const story = campaignGroup.find(c => c.contentType === 'story');

  return (
    <TableRow cursor="default" _hover={{}}>
      <TableCell column={columns[0]}>
        <Flex direction="column" w="100%">
          <Text fontWeight="bold">{campaign.name}</Text>
          <Text>{campaign.description}</Text>
        </Flex>
      </TableCell>
      {contentTypes.map(
        (contentType: string, index: number) => (
          <TableCell column={columns[index + 1]} key={contentType}>
            <CampaignToggle
              campaign={campaignGroup.find(c => c.contentType === contentType)}
              onChange={onChange}
            />
          </TableCell>
        ),
      )}
    </TableRow>
  );
};

export default Campaigns;
